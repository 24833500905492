<!-- 采购订单 -->
<template>
  <div class="zt-page-content">
    <div class="zt-block">
      <div class="thead">
        <el-row v-if="item">
          <el-col :span="5">
            订购日期：
            <span class="font14-grey">{{
              item.createdAt | moment("YYYY/MM/DD HH:mm:ss")
            }}</span>
          </el-col>
          <el-col :span="4">
            代理人：
            <span class="font14-grey">{{ item.buyerName }}</span>
          </el-col>
          <el-col :span="5">
            订单编号：
            <span class="font14-grey" v-if="item.code">{{ item.code }}</span>
          </el-col>
          <el-col :span="5">
            代理商付款状态：
            <span class="font14-grey" v-if="item.state < 5">未付款</span>
            <span class="font14-grey" v-if="item.state == 5">已付款</span>
            <span class="font14-grey" v-if="item.state >= 6">已确认收款</span>
          </el-col>
          <el-col :span="5">
            代理商发货：
            <span class="font14-grey" v-if="item.state <= 6">未发货</span>
            <span class="font14-grey" v-if="item.state == 7">已发货</span>
            <span class="font14-grey" v-if="item.state == 8">已收货</span>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="zt-block block-2" style="padding: 20px 25px">
      <div class="title"><b>商品信息</b></div>
      <div
        v-if="item && item.entries && item.entries.length > 0"
        class="order-list"
      >
        <div v-for="(item1, i) in item.entries" :key="i" class="order-block">
          <div class="order-top">
            <img
              @click="gotoDetail(item1.item)"
              v-if="
                item1.fxItemSku &&
                item1.fxItemSku.spu &&
                item1.fxItemSku.spu.photo_render &&
                item1.fxItemSku.spu.photo_render.length > 0
              "
              :src="imgUrl + item1.fxItemSku.spu.photo_render[0]"
            />
            <div class="img-right">
              <div class="item-name flex flex-align-center">
                <span class="name" @click="gotoDetail(item1.fxItemSku.spu)">{{
                  item1.fxItemSku.spu.name
                }}</span>
                <span class="series" v-if="item1.fxItemSku.spu.series">{{
                  item1.fxItemSku.spu.series.name
                }}</span>
              </div>
              <div class="feature">
                <span v-if="item1.fxItemSku.spu.cat">{{
                  item1.fxItemSku.spu.cat.name
                }}</span>
                <span v-if="item1.fxItemSku.spu.style">{{
                  item1.fxItemSku.spu.style.name
                }}</span>
              </div>
              <div class="feature">
                <span v-if="item1.fxItemSku">{{
                  item1.fxItemSku.material.name
                }}</span>
                <span v-if="item1.fxItemSku">{{
                  item1.fxItemSku.color.name
                }}</span>
                <span
                  v-if="
                    item1.fxItemSku &&
                    item1.fxItemSku.attrs &&
                    item1.fxItemSku.attrs.length > 0
                  "
                >
                  <span
                    v-for="(attr, idx1) in item1.fxItemSku.attrs"
                    :key="idx1"
                  >
                    {{ attr.value }}
                  </span>
                </span>
              </div>
              <!-- <div v-if="item1.fxItemSku.code" class="code">条形编码：{{item1.fxItemSku.code}}</div> -->
              <div class="amount">
                <span>数量</span>
                {{ item1.count }}
              </div>
              <div class="flex">
                <div style="margin-right: 40px">
                  <span style="margin-right: 10px">二级实际批发价</span>
                  <span class="price"
                    >￥{{ item1.fxItemSku.price_lv_2 | priceFilt }}</span
                  >
                </div>
                <div style="margin-right: 40px">
                  <span style="margin-right: 10px">三级实际批发价</span>
                  <span class="price"
                    >￥{{ item1.fxItemSku.price_lv_3 | priceFilt }}</span
                  >
                </div>
                <div>
                  <span style="margin-right: 10px">实际零售价</span>
                  <span class="price"
                    >￥{{ item1.fxItemSku.price_lv_4 | priceFilt }}</span
                  >
                </div>
              </div>
              <div>
                <span>总价</span>
                <b class="price">￥{{ item1.amount | priceFilt }}</b>
              </div>
              <aps :type="1" :item1="item1"></aps>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zt-block" style="padding: 20px 25px">
      <div class="title"><b>收款信息</b></div>
      <div v-if="item && item.amount" class="block-body">
        <div class="font14-grey">
          <label>订单总金额：</label
          ><span class="price"
            ><b>￥{{ item.amount }}</b></span
          >
          <el-button
            v-if="item.state == 5"
            @click="view_pay(item)"
            size="small"
            type="primary"
            >确认收款</el-button
          >
          <el-button
            v-if="item.state == 6"
            @click="view_pay(item)"
            size="small"
            type="primary"
            >查看收款记录</el-button
          >
        </div>
      </div>
    </div>
    <div class="zt-block" style="padding: 20px 25px">
      <div class="title"><b>收货人信息</b></div>
      <div v-if="item && item.consignee" class="block-body">
        <div class="font14-grey">
          <span>收货人姓名：{{ item.consignee.name }}</span>
          <span>收货人手机：{{ item.consignee.tel }}</span>
        </div>
        <div class="font14-grey">送货地址：{{ item.consignee.address }}</div>
      </div>
    </div>
    <div v-if="item" class="zt-block" style="padding: 20px 25px">
      <div class="title"><b>运输信息</b></div>
      <div class="block-body">
        <div class="font14-grey flex">
          <span
            >物流公司：<span v-if="item.tranInfo && item.tranInfo.exName">{{
              item.tranInfo && item.tranInfo.exName
            }}</span></span
          >
          <span>运单号：{{ item.tranInfo && item.tranInfo.exCode }}</span>
          <div class="flex">
            <label>运单号图片：</label>
            <div
              v-if="item.tranInfo && item.tranInfo.exPic"
              class="tranInfo-img"
              @click="viewImg(item.tranInfo.exPic)"
            >
              <div class="masking flex flex-center">点击查看</div>
              <img
                v-if="item.tranInfo"
                :src="imgUrl + item.tranInfo.exPic + '!width_250px'"
              />
            </div>
          </div>
        </div>
        <div>
          <el-button
            v-if="item.state == 7"
            @click="edit_trans(item)"
            style="margin-right: 70px"
            size="small"
            type="primary"
            >修改运输方式</el-button
          >
          <el-button
            v-if="item.state <= 6"
            @click="edit_trans(item)"
            size="small"
            type="primary"
            >给代理商发货</el-button
          >
        </div>
      </div>
    </div>

    <el-dialog
      width="600px"
      :title="popTitle"
      :visible.sync="popVisible"
      :destroy-on-close="true"
    >
      <el-form
        style="width: 500px; margin: 0 auto"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="130px"
        class="ruleFrom"
      >
        <el-form-item
          v-if="popTitle == '修改运输方式'"
          prop="exName"
          label="物流公司"
        >
          <el-input
            placeholder="请输入物流公司名"
            v-model="ruleForm.exName"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '修改运输方式'"
          prop="exCode"
          label="运单号"
        >
          <el-input
            placeholder="请输入运单号"
            v-model="ruleForm.exCode"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '修改运输方式'"
          prop="exPic"
          label="运单号图片"
        >
          <UploadImg
            v-model="ruleForm.exPic"
            :limit="5"
            prefix="agent/logistics"
          ></UploadImg>
        </el-form-item>
        <el-form-item
          v-if="popTitle == '修改价格'"
          prop="price"
          label="二级批发价"
        >
          <el-input
            placeholder="请输入二级批发价"
            v-model="ruleForm.price"
            type="number"
          ></el-input>
        </el-form-item>
        <div class="flex flex-justify-around" style="padding: 20px 0">
          <el-button @click="hide()" style="width: 90px" type="primary" plain
            >取消</el-button
          >
          <el-button
            @click="submitForm('ruleForm')"
            style="width: 90px"
            type="primary"
            >提交</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { IMG_URL_PRE } from "@/config";
import {
  saleOrderGetId,
  orderEntrieSetPrice,
  orderSetTranInfo,
  orderSend,
} from "@/service/sharer";
import { getAPSByOrderId } from "@/service/sale";
import UploadImg from "@/components/upload/UploadImg.vue";

export default {
  components: { UploadImg },
  data() {
    return {
      imgUrl: IMG_URL_PRE,
      popVisible: false,
      popTitle: "",
      queryOpt: {
        hasBuyerInfo: true,
        hasSkuInfo: true,
        hasTradeOrder: true,
        hasPurOrder: true,
      },
      total: 0,
      item: null,
      rules: {
        exName: [
          { required: true, message: "请输入运输人姓名", trigger: "blur" },
        ],
        exCode: [{ required: true, message: "请输入运单号", trigger: "blur" }],
        price: [{ required: true, message: "请输入价格", trigger: "blur" }],
      },
      ruleForm: {
        exName: null,
        exCode: null,
        exPic: "",
        price: "",
      },
      entriesId: null,
    };
  },
  async created() {
    this.apsInfo = await this.getApsDetail(this.$route.params.id);
    this.refreshItems();
  },
  methods: {
    getApsDetail(id) {
      return new Promise((resolve, reject) => {
        getAPSByOrderId(id).then((rst) => {
          console.log("rstgetApsDetail", rst);
          resolve([
            {
              // itemId: "5523903619df4a46a2410674774e7568",
              itemId: rst.itemId,
              list: rst.lists,
            },
          ]);
        });
      });
    },
    goto(path) {
      this.$router.push(path);
    },
    gotoDetail(item) {
      console.log(item);
      window.open("/factory/zysp/detail/" + item.factory_product_no);
    },
    // 查看图片
    viewImg(src) {
      window.open(this.imgUrl + src + "!width_800px");
    },
    refreshItems() {
      console.log("refreshItems");
      saleOrderGetId(this.$route.params.id, this.queryOpt)
        .then((rst) => {
          this.item = rst;
          let data = [];
          let arr = this.item.entries.map((item) => {
            let list = [];
            if (this.apsInfo && this.apsInfo.length > 0) {
              list = this.apsInfo
                .filter((aps) => aps.itemName === item.itemName)
                .map((item) => {
                  return item;
                });

              console.error("list", list);
              list.forEach((item) => {
                item.lists.forEach((record) => {
                  data.push({
                    startDate: record.startDate,
                    endDate: record.endDate,
                    planNo: record.planNo,
                    plannedQuantity: record.plannedQuantity,
                    due: item.due,
                    plannedShort: item.plannedShort,
                  });
                });
              });
            }

            return { ...item, mesInfo: { list: data } };
          });
          this.$set(this.item, "entries", arr);
          console.log(this.item);
        })
        .catch((err) => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    // 修改价格
    edit_price(item) {
      this.popTitle = "修改价格";
      this.popVisible = true;
      this.entriesId = item.id;
      this.ruleForm = {
        price: item.price,
      };
    },
    // 修改运输方式
    edit_trans(item) {
      if ([5, 6, 7].indexOf(this.item.state) < 0) {
        this.$message.error(
          "只能对状态为[买方已付款/卖方已收款/卖家已发货]的订单设置物流信息!"
        );
        return;
      }
      this.popTitle = "修改运输方式";
      this.popVisible = true;
      if (item && item.tranInfo) {
        this.ruleForm = {
          exName: item.tranInfo.exName,
          exCode: item.tranInfo.exCode,
          exPic: item.tranInfo.exPic,
        };
      }
    },
    hide() {
      this.popVisible = false;
    },
    submitForm(formName) {
      console.log(this.ruleForm);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.popTitle == "修改运输方式") {
            this.$delete(this.ruleForm, "price");
            orderSetTranInfo(this.item.id, this.ruleForm)
              .then(() => {
                this.hide();
                this.refreshItems();
                this.$message.success("修改运输方式成功");
              })
              .catch((err) => {
                this.$message.error(err.message);
              });
          } else if (this.popTitle == "修改价格") {
            this.order_price();
          }
        } else {
          return false;
        }
      });
    },
    // 修改订单价格
    order_price() {
      this.$delete(this.ruleForm, "exName");
      this.$delete(this.ruleForm, "exCode");
      this.$delete(this.ruleForm, "exPic");
      this.ruleForm.price = parseFloat(this.ruleForm.price);
      orderEntrieSetPrice(this.entriesId, this.ruleForm)
        .then(() => {
          this.hide();
          this.refreshItems();
          this.$message.success("修改价格成功");
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    // 查看收款记录
    view_pay(item) {
      this.$router.push({
        path: "/pay/payeeList",
        query: {
          order_id: item.tradeOrderId,
          order_type: "fx",
        },
      });
    },
  },
};
</script>
<style>
</style>
<style scoped src="../../style.css"></style>
<style scoped>
.select-wrap >>> .el-date-editor .el-range-separator {
  width: 10%;
}
</style>
<style scoped lang="less">
.thead {
  background: rgba(245, 245, 245, 1);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  font-size: 14px;
  color: #808080;
  font-weight: 400;
  .el-col {
    height: 60px;
    text-align: center;
    line-height: 60px;
  }
}
.title {
  font-size: 16px;
  color: #292929;
  line-height: 20px;
  padding-bottom: 15px;
}
.price {
  font-size: 16px;
  color: #f66f6a;
}
.tranInfo-img {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  &:hover .masking {
    opacity: 1;
  }
  .masking {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    font-size: 14px;
    color: #fff;
    transition: all 0.18s linear;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
.block-body {
  > div {
    margin-bottom: 10px;
    > span {
      margin-right: 60px;
    }
  }
  > div:last-child {
    margin-bottom: 0;
  }
}
.block-2 {
  .order-list {
    padding: 0 71px;
    .order-block {
      color: #808080;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .order-top {
        display: flex;
        img {
          width: 220px;
          height: 220px;
          cursor: pointer;
        }
        .img-right {
          margin-left: 18px;
          flex: 1;
          > div {
            margin-top: 16px;
            &:first-child {
              margin-top: 0;
            }
          }
          .item-name {
            font-size: 18px;
            font-weight: bold;
            span.name {
              cursor: pointer;
            }
            span.series {
              display: inline-block;
              width: 132px;
              text-align: center;
              height: 21px;
              font-size: 14px;
              margin-left: 30px;
              background: linear-gradient(
                90deg,
                rgba(251, 199, 190, 1) 0%,
                rgba(254, 165, 166, 1) 100%
              );
              border-radius: 4px;
              color: #fff;
            }
          }
          .feature {
            display: flex;
            span {
              padding: 0 10px;
              border-right: 1px solid #707070;
              line-height: 1;

              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                border-right: 0;
              }
            }
          }
          .price {
            b {
              color: #ee1818;
              font-weight: 400;
            }
          }
          .amount,
          .price,
          .logistic-status {
            span {
              margin-right: 16px;
            }
          }
        }
      }
      .order-btm {
        padding: 18px 0;
        > div {
          padding: 10px 0;
          text-align: center;
          border: 1px solid #e6e6e6;
        }
        table {
          width: 100%;
          text-align: center;
          border: 1px solid #e6e6e6;
          margin-top: 24px;
          &:first-child {
            margin-top: 0;
          }
          th {
            height: 40px;
            background-color: #f5f5f5;
          }
          td {
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
